import BlogCard from '../BlogCard';

function BlogList(props) {
	return (
		<div className="row">
			{props.blogData.length > 0 && props.blogData.map(blog => {
				return (
					<div key={blog.id} className="col-sm-4 mt-3">
						<BlogCard
							backgroundColor={`#${blog.color}`}
							BlogTitle={blog.title}
							readTimeInMin={blog.read_duration}
							publishDate={new Date(blog.created_on).toLocaleDateString()}
							blogId={blog.id}
							isDraft={blog.is_draft}
							showDialog={(val) => {
								props.openDialog(val, blog);
							}}
						/>
					</div>
				)
			})}
		</div>
	);
}

export default BlogList;