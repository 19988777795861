import { useEffect, useState } from "react";
import Navbar from "../components/Navbar"
import SunEditor from "suneditor-react";
import { Close } from "@mui/icons-material";
import { Dialog, AppBar, IconButton, Typography, Button, Toolbar, DialogContent, TextField, Checkbox, FormControlLabel, Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { onValue, push, ref, set, update } from "firebase/database";
import { db } from "../utils/firebase";
import ProjectList from "../components/ProjectList";
import { currentUser } from "../utils/auth_service";
import dayjs from "dayjs";

export default function ProjectsPage() {
    const [openDialog, setOpenDialog] = useState(false);
    const [name, setName] = useState('');
    const [oneLiner, setOneLiner] = useState('');
    const [description, setDescription] = useState('');
    const [url, setURL] = useState('');
    const [imageUrl, setImageURL] = useState('');
    const [category, setCategory] = useState('');
    const [InProgress, setInProgress] = useState(false);
    const [data, setData] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [user, setUser] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [selectedProject, setSelectedProject] = useState({});
    const [projectCategories, setProjectCategories] = useState([]);

    const openCreateProjectForm = () => {
        setOpenDialog(true);
    }

    const onProjectNameChange = (e) => {
        setName(e.target.value);
    }

    const onOneLinerChange = (e) => {
        setOneLiner(e.target.value);
    }

    const onImageURLChange = (e) => {
        setImageURL(e.target.value);
    }

    const onDescriptionChange = (e) => {
        setDescription(e);
    }

    const onURLChange = (e) => {
        setURL(e.target.value);
    }

    // const onCategoryChange = (e) => {
    //     setCategory(e.target.value);
    // }

    const onWorkingProjectChange = (e) => {
        setInProgress(!InProgress);
    }

    const onStartDateChange = (e) => {
        setStartDate(new Date(e).valueOf());
    }

    const onEndDateChange = (e) => {
        setEndDate(new Date(e).valueOf());
    }

    const handleClose = () => {
        setOpenDialog(false);
        setName('');
        setOneLiner('');
        setDescription('');
        setCategory('');
        setImageURL('');
        setURL('');
        setStartDate(null);
        setEndDate(null);
        setIsEdit(false);
        setSelectedProject({});
    }

    useEffect(() => {
        currentUser.subscribe((val) => {
            setUser(val)
        })
    }, [])

    const createProject = (isDraft) => {
        const query = ref(db, "projects");
        const postRef = push(query);
        let data = {};
        data.description = description;
        data.category = category;
        data.name = name;
        data.one_liner = oneLiner;
        data.url = url;
        data.image_url = imageUrl;
        data.end_date = endDate;
        data.start_date = startDate;
        data.isCurrentlyWorking = InProgress;
        data.created_by = user.uid;
        data.updated_by = user.uid;
        data.is_draft = isDraft;
        data.is_deleted = false;
        data.created_on = new Date().getTime();
        data.updated_on = new Date().getTime();
        data.id = postRef.key;

        console.log(data)
        set(postRef, data).then(() => {
            // console.log(res)
            console.log("Project Success!!")
        }).catch(err => {
            console.log(err)
        });
    }

    const updateProject = (isDraft) => {
        selectedProject.description = description;
        selectedProject.category = category;
        selectedProject.name = name;
        selectedProject.one_liner = oneLiner;
        selectedProject.url = url;
        selectedProject.image_url = imageUrl;
        selectedProject.end_date = endDate;
        selectedProject.start_date = startDate;
        selectedProject.isCurrentlyWorking = InProgress;
        selectedProject.updated_by = user.uid;
        selectedProject.is_draft = isDraft;
        selectedProject.is_deleted = false;
        selectedProject.updated_on = new Date().getTime();

        const query = ref(db, `projects/${selectedProject.id}`);
        update(query, selectedProject).then(() => {
            console.log("Updated Successfully!!")
        })
            .catch((err) => {
                console.log(err);
            })
    }

    const onSave = (isDraft) => {
        if (isEdit) {
            updateProject(isDraft);
        }
        else {
            createProject(isDraft);
        }
        handleClose();
    }

    const updateCategories = (cat) => {
        if(cat.length !== 0){
            console.log(cat)
            const q = ref(db, 'project_categories');
            set(q, cat).then(() => {
                console.log("Categories updated successfully!")
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }

    const options = {
        buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["fontColor", "hiliteColor", "textStyle"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link", "image", "video", "audio"],
            ["imageGallery"],
            ["fullScreen", "showBlocks", "codeView"],
            ["preview", "print"],
            ["save", "template"]
        ],
    }
    useEffect(() => {
        const query = ref(db, 'projects');
        onValue(query, (snapshot) => {
            let arr = []
            snapshot.forEach((child) => {
                arr.push(child.val());
            });
            // console.log("working");
            setData(arr);
        }, {
            onlyOnce: true,
        });

        const q1 = ref(db, "project_categories");
        onValue(q1, (snapshot) => {
            let a = [];
            snapshot.forEach((child) => {
                a.push(child.val());
            });
            // console.log(a);
            setProjectCategories(a);
        }, {
            onlyOnce: true,
        });
    }, []);
    return (
        <div className='body_background'>
            <Navbar openCreateProjectForm={openCreateProjectForm} />
            <ProjectList
                data={data}
                openDialog={(val, project) => {
                    setOpenDialog(val);
                    setCategory(project.category);
                    setName(project.name);
                    setOneLiner(project.one_liner);
                    setDescription(project.description);
                    setStartDate(new Date(project.start_date));
                    setEndDate(new Date(project.end_date));
                    setURL(project.url);
                    setImageURL(project.image_url);
                    setInProgress(project.isCurrentlyWorking);
                    setIsEdit(true);
                    setSelectedProject(project);
                }}
            />
            <Dialog fullScreen open={openDialog} onClose={handleClose}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge='start'
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close">
                            <Close></Close>
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Create Project
                        </Typography>
                        <Button disabled={selectedProject.is_draft} autoFocus color="inherit" onClick={() => { onSave(true) }}>
                            draft
                        </Button>
                        <Button autoFocus color="inherit" onClick={() => { onSave(false) }}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Project Name"
                        variant="outlined"
                        value={name}
                        onChange={onProjectNameChange}
                    />
                    <TextField
                        className='mt-3'
                        fullWidth
                        id="outlined-basic"
                        label="Enter One Liner"
                        variant="outlined"
                        value={oneLiner}
                        onChange={onOneLinerChange}
                    />
                    <TextField
                        className='mt-3'
                        fullWidth
                        id='outlined-basic'
                        label="Image url"
                        variant='outlined'
                        value={imageUrl}
                        onChange={onImageURLChange}
                    />
                    <TextField
                        className='mt-3'
                        fullWidth
                        id='outlined-basic'
                        label="Project's URL"
                        variant='outlined'
                        value={url}
                        onChange={onURLChange}
                    />
                    <Autocomplete
                        className="mt-3"
                        value={category}
                        options={ projectCategories }
                        noOptionsText="Enter to create a new option"
                        getOptionLabel={(option) => option}
                        onInputChange={(e, newValue) => {
                            setCategory(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Category"
                                variant="outlined"
                                onKeyDown={(e) => {
                                    if (
                                        e.key === "Enter" &&
                                        projectCategories.findIndex((o) => o === category) === -1
                                    ) {
                                        setProjectCategories((o) => {
                                            updateCategories(o.concat(category));
                                            return o.concat(category)
                                        });
                                    }
                                }}
                            />
                        )}
                    />
                    <div className="mt-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="d-flex justify-content-between align-items-center">
                                <DatePicker
                                    className="flex-fill"
                                    label="Start Date"
                                    value={dayjs(startDate)}
                                    onChange={onStartDateChange}
                                />
                                <div className="p-3">-</div>
                                <DatePicker
                                    className="flex-fill"
                                    label="End Date"
                                    value={dayjs(endDate)}
                                    disabled={InProgress}
                                    onChange={onEndDateChange}
                                />
                            </div>
                        </LocalizationProvider>
                    </div>
                    <div className="mt-3">
                        <FormControlLabel
                            checked={InProgress}
                            control={<Checkbox />}
                            label="Currently working on this project"
                            onChange={onWorkingProjectChange}
                        />
                    </div>
                    <div className='mt-3'>
                        <SunEditor
                            name="myeditor"
                            placeholder="Type description here ..."
                            defaultValue={description}
                            onChange={onDescriptionChange}
                            setOptions={options}
                            height='300px'
                        />
                    </div>

                </DialogContent>
            </Dialog>
        </div>
    )
}