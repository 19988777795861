import { onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { auth, provider } from "./firebase";
import { BehaviorSubject } from 'rxjs'

export const currentUser = new BehaviorSubject({})

onAuthStateChanged(auth, (user) => {
    if(user){
        currentUser.next(user)
        console.log("Logged In as = ", user.email)
    }
    else{
        // console.log("State Change: Logged out!")
        currentUser.next({})
    }
})
export function signIn(){
    signInWithPopup(auth, provider)
    .then((res) => {
        currentUser.next(res.user)
        // console.log(res);
    })
    .catch((err) => {
        console.log(err);
    })
}
export function logOut(){
    signOut(auth).then(()=> {
        // console.log("Logout success!!")
    })
    .catch((err) => {
        console.log(err);
    })
}