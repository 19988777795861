import { Launch } from "@mui/icons-material";
import { useEffect, useState } from "react"

export default function ProjectList(props){
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        var newArr = []
        props.data.map((proj) => {
            newArr.push(proj);
        })
        setProjects(newArr);
    }, [props.data])

    const openDialog = (p) => {
        props.openDialog(true, p);
    }

    return (
        <div className="container mt-5">
            <div className="row">
                {projects?.map((p) => {
                    return (
                    <div key={p.name} className="col-sm-4 mt-3 mb-3" onClick={() => openDialog(p)}>
                        <div className="card w-100 p-3 text-light position-relative" style={{backgroundImage: `url(${p.image_url})`, backgroundSize: 'cover',height: '300px'}}>
                            {p.is_draft && <div className="badge px-3 py-2 bg-primary pill w-25">Draft</div>}
                            <div className="position-absolute end-0 top-0">
                                <a href={p.url} target="_blank">
                                    <Launch className="m-3 text-light"></Launch>
                                </a>
                            </div>
                            <div className="position-absolute bottom-0">
                                <h1>{p.name}</h1>
                                <h5>{p.one_liner}</h5>
                            </div>
                        </div>
                        <div className="m-auto position-relative bg-light p-3" style={{height: '300px', width: '90%', borderRadius: '0px 0px 5px 5px', overflowY: 'scroll'}}>
                            <div dangerouslySetInnerHTML={{__html: p.description}}></div> <br></br><br></br>
                            <b>Started On: </b>{new Date(p.start_date).toLocaleDateString()} <br></br>
                            {p.isCurrentlyWorking && <>
                                <b>Project is currently in progress</b>
                            </>}
                            {!p.isCurrentlyWorking && <>
                                <b>Completed On: </b>{new Date(p.end_date).toLocaleDateString()}
                            </>}
                        </div>
                    </div>
                    )
                })}
            </div>
        </div>
    )
}