import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import ProjectsPage from './pages/Projects';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={HomePage()}></Route>
        <Route path='projects' element={ProjectsPage()}></Route>
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;
