import '../App.css';
import { AppBar, Button, Dialog, DialogContent, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import BlogList from '../components/BlogList';
import { db } from '../utils/firebase';
import { onValue, push, ref, set, update } from 'firebase/database';
import Navbar from '../components/Navbar';
import { currentUser } from '../utils/auth_service';
import authFailImage from '../assets/images/authentication.svg';

export default function HomePage() {

    const [openDailog, setOpenDialog] = useState(false);
    const [title, setTitle] = useState('');
    const [oneLiner, setOneLiner] = useState('');
    const [content, setContent] = useState('');
    const [keywords, setKeywords] = useState('');
    const [selectedBlog, setSelectedBlog] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [data, setData] = useState([]);
    const [user, setUser] = useState({});
    const [isInputChanged, setIsInputChanged] = useState(false);

    useEffect(() => {
        currentUser.subscribe((val) => {
            setUser(val)
        })
    }, [])

    const onSave = (isDraft) => {
        // console.log(title, oneLiner, content)
        if (!isEdit) {
            createBlog(isDraft);
        }
        else {
            updateBlog(isDraft);
        }
        handleClose();
    }

    const updateBlog = (isDraft) => {
        selectedBlog.title = title;
        selectedBlog.content = content;
        selectedBlog.one_liner = oneLiner;
        selectedBlog.keywords = keywords;
        selectedBlog.is_draft = isDraft;
        selectedBlog.updated_on = new Date().getTime();
        selectedBlog.updated_by = user.uid;

        const query = ref(db, `blogs/${selectedBlog.id}`);
        update(query, selectedBlog).then(() => {
            console.log("Updated Successfully!!")
        })
        .catch((err) => {
            console.log(err);
        })
    }

    const createBlog = (isDraft) => {
        const query = ref(db, 'blogs');
        const postRef = push(query);
        let data = {};
        data.content = content;
        data.title = title;
        data.one_liner = oneLiner;
        data.keywords = keywords;
        data.read_duration = 5;
        data.created_by = user.uid;
        data.updated_by = user.uid;
        data.is_draft = isDraft;
        data.is_deleted = false;
        data.created_on = new Date().getTime();
        data.updated_on = new Date().getTime();
        data.id = postRef.key;
        data.color = Math.floor(Math.random() * 16777215).toString(16);

        set(postRef, data).then(() => {
            // console.log(res)
            console.log("Post Success!!")
        }).catch(err => {
            console.log(err)
        });
    }
    const onTitleChange = (event) => {
        setTitle(event.target.value);
        setIsInputChanged(true);
    }
    const onOneLinerChange = (event) => {
        setOneLiner(event.target.value);
        setIsInputChanged(true);
    }
    const onKeywordsChange = (event) => {
        setKeywords(event.target.value);
        setIsInputChanged(true);
    }
    const onChangeEvent = (event) => {
        setContent(event);
        setIsInputChanged(true);
    }
    const openCreateBlogForm = () => {
        setOpenDialog(true);
    }

    const handleClose = () => {
        setOpenDialog(false);
        setContent('')
        setTitle('');
        setIsEdit(false);
        setOneLiner('');
        setKeywords('');
        setSelectedBlog({});
        setIsInputChanged(false);
    };

    const options = {
        buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["fontColor", "hiliteColor", "textStyle"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link", "image", "video", "audio"],
            ["imageGallery"],
            ["fullScreen", "showBlocks", "codeView"],
            ["preview", "print"],
            ["save", "template"]
        ],
    }

    useEffect(() => {
        const query = ref(db, 'blogs');
        onValue(query, (snapshot) => {
            let arr = []
            snapshot.forEach((child) => {
                arr.push(child.val());
            });
            setData(arr);
        })
    }, []);
    return (
        <div className='body_background'>
            <Navbar openCreateBlogForm={openCreateBlogForm} />
            <div className="container pt-5 position-relative pb-5">
                {user?.email && <BlogList
                    blogData={data}
                    openDialog={(val, blog) => {
                        setOpenDialog(val);
                        setContent(blog.content)
                        setTitle(blog.title);
                        setOneLiner(blog.one_liner);
                        setKeywords(blog.keywords);
                        setIsEdit(true);
                        setSelectedBlog(blog);
                    }}
                />}
                {!user?.email && <div className='text-center text-light'>
                    <h1>Welcome to Admin Panel</h1>
                    <p>Please login to access posts </p>
                    <img src={authFailImage} alt={"Auth Image"} className='col-12 col-sm-3 mt-5' />
                </div>}
                <Dialog fullScreen open={openDailog} onClose={handleClose}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge='start'
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close">
                                <Close></Close>
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Create Blog
                            </Typography>
                            
                            <Button disabled={selectedBlog.is_draft && !isInputChanged} autoFocus color="inherit" onClick={() => {onSave(true)}}>
                                draft
                            </Button>
                            <Button autoFocus color="inherit" onClick={() => {onSave(false)}}>
                                save
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Blog Title"
                            variant="outlined"
                            value={title}
                            onChange={onTitleChange}
                        />
                        <TextField
                            className='mt-3'
                            fullWidth
                            id="outlined-basic"
                            label="Enter One Liner"
                            variant="outlined"
                            value={oneLiner}
                            onChange={onOneLinerChange}
                        />
                        <TextField
                            className='mt-3'
                            fullWidth
                            id='outlined-basic'
                            label="Enter keywords separated with commas"
                            variant='outlined'
                            value={keywords}
                            onChange={onKeywordsChange}
                        />
                        <div className='mt-3'>
                            <SunEditor
                                name="myeditor"
                                placeholder="Type your blog here..."
                                defaultValue={content}
                                onChange={onChangeEvent}
                                setOptions={options}
                                height='300px'
                            />
                        </div>

                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}