import { Public, AccessTime } from '@mui/icons-material';

function BlogCard(props) {
    // const navigate = useHistory();
    const openBlog = () => {
        // navigate.push(`/blogs/${props.blogId}`)
        props.showDialog(true)
    }
    return (
        <div className='card card-box text-white shadow-lg position-relative' 
        style={{ width: '100%', height: '250px', background: props.backgroundColor, border: 'none', cursor: 'pointer' }}>
            <div className='p-3' style={{ height: '70%', width: '100%' }}
            onClick={openBlog}>
                {props.isDraft && <div className='badge px-3 py-2 rounded-pill bg-primary float-end' style={{width: 'fit-content'}}>Draft</div>}
                <div className='fs-3'>{props.BlogTitle}</div>
            </div>
            <div className='position-absolute p-3 bottom-0 shadow-lg' style={{ height: '30%', width: '100%', background: 'white',border:'none', borderRadius: '10px 10px 5px 5px' }}>
                <span className='d-block text-dark' style={{ fontSize: '14px' }}>
                    <Public /> &nbsp; {props.readTimeInMin} min Read <br></br>
                    <AccessTime /> &nbsp; Published on {props.publishDate}
                </span>
            </div>
        </div>
    )
}

export default BlogCard;