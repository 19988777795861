import { useEffect, useState } from "react";
import { currentUser, logOut, signIn } from "../../utils/auth_service";
import { Link, useLocation } from "react-router-dom";

function Navbar(props) {
    const [user, setUser] = useState({});
    const location = useLocation();
    const path = location.pathname.slice(1);

    useEffect(() => {
        currentUser.subscribe((val) => {
            setUser(val)
        })
    }, [])

    return (
        <nav style={{
            zIndex: '2',
            background: 'rgba( 0, 0, 0, 0.5 )',
            boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
            // borderRadius: '0px 0px 10px 10px',
            backdropFilter: 'blur(6px)'
        }}
            className='d-sm-flex w-100 fixed-position align-items-center p-3 bg-dark text-light text-center justify-content-between'>
            <div className="p-3 p-sm-0">Dev-Akash</div>
            <div className='d-flex justify-content-center'>
                {user?.email && path && <div className='badge rounded-pill px-3 py-2 bg-primary'
                    role='button'>
                        <Link to='/' className="text-light text-decoration-none">Blogs</Link>
                </div>}
                {user?.email && path !== 'projects' && <div className='badge rounded-pill ms-3 px-3 py-2 bg-primary'
                    role='button'>
                        <Link to='/projects' className="text-light text-decoration-none">Projects</Link>
                </div>}
                {user?.email && props.openCreateProjectForm && <div className='badge rounded-pill px-3 py-2 ms-3 bg-primary'
                    role='button'
                    onClick={props.openCreateProjectForm}>
                    Create Project
                </div>}
                {user?.email && props.openCreateBlogForm && <div className='badge rounded-pill px-3 py-2 ms-3 bg-primary'
                    role='button'
                    onClick={props.openCreateBlogForm}>
                    Create Post
                </div>}
                <div className='ms-3'>
                    {!user?.email ?
                        <div role='button' className='badge rounded-pill px-3 py-2 bg-primary' onClick={signIn}>Login with Google</div>
                        : <div role='button' className='badge rounded-pill px-3 py-2 bg-danger' onClick={logOut}>Logout</div>}
                </div>
            </div>
        </nav>
    )
}
export default Navbar;