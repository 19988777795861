// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCOoI90e1vuV-hJ85mKKKKrKrY381kdRJA",
  authDomain: "akash-s-react-portfolio.firebaseapp.com",
  projectId: "akash-s-react-portfolio",
  storageBucket: "akash-s-react-portfolio.appspot.com",
  messagingSenderId: "1096394563591",
  appId: "1:1096394563591:web:bddefbc9db9484026d0aa2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Auth
export const provider = new GoogleAuthProvider();

export const db = getDatabase(app);
export const auth = getAuth();